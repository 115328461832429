$theme: ekonomi;
@use "design-system" as ds;

.container {
  position: relative;
  display: flex;
}

.wrapper {
  position: relative;
}

.articleWrapper {
  width: 100%;
}

.placement {
  position: relative;
  padding: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;

  &.articleResource {
    margin-top: ds.spacing("regular-increased");
    padding: 0 ds.spacing();
  }

  &.collapsed {
    visibility: hidden;
    height: 0;
    & > * {
      visibility: hidden;
    }
  }

  // Hides ad when collapsed but lets it take up space
  &.keepSizeButHidden {
    background-color: transparent;
    & > * {
      visibility: hidden;
    }
  }
}

.wideWrapper {
  display: block;
  width: 100%;
}

.loader {
  border-radius: 2px;
  display: block;
  line-height: 100%;
  width: 100%;
  background-color: #fff;
  background-size: 1000px 1000px;
  @include ds.background-for-shimmer-effect(
    placeholder--secondary,
    secondary,
    $theme: $theme
  );
  animation: shimmerEffect 1.5s linear infinite forwards;
}

.fullscreenScroll {
  top: 86px;
  overflow: hidden;
  transition: opacity 0.5s ease;
}

// Only uses important here because ad service uses inline styling
// and for this fix we need to override the height and width
// because its often wrong for this position
// For ref: see https://schibstedio.atlassian.net/browse/PLATOPS-6880
.dynamicSizing > :first-child {
  width: 100% !important;
  height: auto !important;
}

/* Skeleton animation*/
@keyframes shimmerEffect {
  0% {
    background-position: -500px 0;
  }
  100% {
    background-position: 500px 0;
  }
}

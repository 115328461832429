@use "sass:math";
@use "colors" as *;
@use "typography" as *;
@use "sizes" as *;

/*
  RESETTERS
  ---------
*/

%reset-list-styles {
  margin: 0;
  list-style-type: none;
  padding: 0;
}

%reset-blockquote-styles {
  margin: 0;
}

/*
  GETTERS
  -------
*/

@function color($group, $definition, $theme, $mode: light) {
  @each $group-name, $group-def in $colors {
    @if $group-name == $group {
      @each $definition-name, $definition-def in $group-def {
        @if $definition-name == $definition {
          @return map-get($definition-def, $mode, $theme);
        }
      }
    }
  }
  @error "Declaration missing";
}

@function spacing($size: regular) {
  @return map-get($sizes, $size);
}

@function typography-prop($name, $theme, $prop) {
  @return map-get($typography, $name, $theme, $prop);
}

@function font-stack($name, $theme) {
  @return #{map-get($font-stacks, map-get($typography, $name, "type"), $theme)};
}

@function scaling-factor($name, $theme) {
  @return map-get($typography, $name, "scaling");
}

/*
  MIXINS
  ------
*/

@mixin colorise-icon($group, $definition, $theme: core) {
  svg path {
    fill: color($group, $definition, $theme: $theme);
    @include darkmode {
      fill: color($group, $definition, $theme: $theme, $mode: dark);
    }
  }
}

@mixin card($theme, $collapsing: false) {
  @include background(primary, $theme: $theme);

  @if $collapsing == false {
    @include border(divider--subtle, $a: 1px, $theme: $theme);
    border-radius: $border-radius--default;
  }

  @if $collapsing == true {
    @include border(divider--subtle, $t: 1px, $b: 1px, $theme: $theme);

    @media (min-width: #{$screen-size--small}) {
      @include border(divider--subtle, $a: 1px, $theme: $theme);
      border-radius: $border-radius--default;
    }
  }
}

@mixin typography($name, $theme, $responsive: true, $context: null) {
  $scaling-factor: scaling-factor($name, $theme);
  $font-size: typography-prop($name, $theme, font-size);
  $font-size-upscaled: round($font-size * $scaling-factor);
  $line-height: math.div(
    typography-prop($name, $theme, line-height),
    typography-prop($name, $theme, font-size)
  );

  font-family: font-stack($name, $theme);
  font-size: #{$font-size}px;
  line-height: $line-height;
  font-weight: typography-prop($name, $theme, font-weight);
  letter-spacing: typography-prop($name, $theme, letter-spacing);
  --typography-definition-name: #{$name}; // For debugging only, should be removed in prod

  @if $responsive {
    @media (min-width: #{$screen-size--small}) {
      font-size: #{$font-size-upscaled}px;
    }
  }
}

@mixin a11y-hidden() {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  white-space: nowrap;
  width: 1px;
}

@mixin divider($theme: core) {
  border-top: 1px solid color(ui, divider--subtle, $theme: $theme);
  margin: 0;

  @include darkmode {
    border-color: color(ui, divider--subtle, $theme: $theme, $mode: dark);
  }
}

@mixin border(
  $definition,
  $a: null,
  $t: 0px,
  $r: 0px,
  $b: 0px,
  $l: 0px,
  $theme: core
) {
  @if $a != null {
    border: $a solid color(ui, $definition, $theme: $theme);
  } @else {
    border-width: $t $r $b $l;
    border-color: color(ui, $definition, $theme: $theme);
    border-style: solid;
  }

  @include darkmode {
    border-color: color(ui, $definition, $theme: $theme, $mode: dark);
  }
}

@mixin text-color($definition, $theme: core) {
  color: color(text, $definition, $theme: $theme);
  @include darkmode {
    color: color(text, $definition, $theme: $theme, $mode: dark);
  }
}

@mixin body-link($theme: core) {
  text-decoration: underline;
  color: color(text, primary, $theme: $theme);

  @include darkmode {
    color: color(text, primary, $theme: $theme, $mode: dark);
  }

  &:hover {
    color: color(text, brand, $theme: $theme);
    @include darkmode {
      color: color(text, brand, $theme: $theme, $mode: dark);
    }
  }
}

@mixin box-shadow($type, $theme: core) {
  @if $type == inset {
    box-shadow: inset 0 0 5px #ccc8;
    @include darkmode {
      box-shadow: none;
    }
  }
  @if $type == card {
    box-shadow: 0 0 5px #ccc8;
    @include darkmode {
      box-shadow: 0 0 1px #eee8;
    }
  }
}

@mixin background(
  $definition,
  $namespace: background,
  $alpha: null,
  $theme: core
) {
  // Use for page and section backgrounds
  // Accepts optional alpha for applying opacity to background

  $light-mode-color: color($namespace, $definition, $theme: $theme);
  $dark-mode-color: color($namespace, $definition, $theme: $theme, $mode: dark);

  @if $alpha != null {
    background-color: rgba($light-mode-color, $alpha);
    @include darkmode {
      background-color: rgba($dark-mode-color, $alpha);
    }
  } @else {
    background-color: $light-mode-color;
    @include darkmode {
      background-color: $dark-mode-color;
    }
  }
}

@mixin background-gradient-from-to(
  $definition-from,
  $definition-to,
  $theme: core
) {
  // Creates a gradient from two background color definitions
  background: linear-gradient(
    color(background, $definition-from, $theme: $theme),
    color(background, $definition-to, $theme: $theme)
  );
  @include darkmode {
    background: linear-gradient(
      color(background, $definition-from, $theme: $theme, $mode: dark),
      color(background, $definition-to, $theme: $theme, $mode: dark)
    );
  }
}

@mixin background-gradient-transparent-to($definition, $theme: core) {
  // Creates a gradient from transparent to the target color definition
  background: linear-gradient(
    transparent,
    color(background, $definition, $theme: $theme)
  );
  @include darkmode {
    background: linear-gradient(
      transparent,
      color(background, $definition, $theme: $theme, $mode: dark)
    );
  }
}

@mixin background-for-shimmer-effect(
  $definition-from,
  $definition-to,
  $theme: core
) {
  // Creates a gradient from two background color definitions suitable to
  // animate with a shimmer effect for placeholder/loading states
  background: linear-gradient(
    100deg,
    color(background, $definition-from, $theme: $theme) 20%,
    color(background, $definition-to, $theme: $theme) 50%,
    color(background, $definition-from, $theme: $theme) 60%
  );
  @include darkmode {
    background: linear-gradient(
      100deg,
      color(background, $definition-from, $theme: $theme, $mode: dark) 20%,
      color(background, $definition-to, $theme: $theme, $mode: dark) 50%,
      color(background, $definition-from, $theme: $theme, $mode: dark) 60%
    );
  }
}

@mixin element-background($definition, $alpha: null, $theme: core) {
  // Use for UI elements
  // Uses the background mixin but with the "ui" namespace for colors
  @include background(
    $definition,
    $namespace: ui,
    $alpha: $alpha,
    $theme: $theme
  );
}

@mixin stack($size: regular, $min-screen-size: none) {
  @if $min-screen-size == none {
    padding-top: spacing($size);
  } @else {
    @if $min-screen-size == small {
      @media (min-width: $screen-size--small) {
        padding-top: spacing($size);
      }
    }
    @if $min-screen-size == medium {
      @media (min-width: $screen-size--medium) {
        padding-top: spacing($size);
      }
    }
    @if $min-screen-size == large {
      @media (min-width: $screen-size--large) {
        padding-top: spacing($size);
      }
    }
  }
}

@mixin pad($size: regular) {
  padding: spacing($size);
}

@mixin pad-lr($size: regular) {
  padding-left: spacing($size);
  padding-right: spacing($size);
}

@mixin darkmode {
  [data-theme="dark"] & {
    @content;
  }
}

@mixin lightmode {
  // Default to light mode if not explicit dark mode set
  [data-theme]:not([data-theme="dark"]),
  :not([data-theme]) & {
    @content;
  }
}

/*
  VARIABLES
  ---------
*/

// z-indexes
$z-index--cover: 500;
$z-index--modal: 1000;
$z-index--menu: 100;
$z-index--floating: 1;

// misc
$border-radius--default: 4px;
$box-shadow--card: 0 0 5px #ccc8;
$box-shadow--inset: inset 0 0 5px #ccc8;

// scaling
$desktop-upscaling-factor: 1.2;

// Ad widths
$ad-width--module: 640px;
$ad-width--panorama: 980px;
$ad-width--widescreen: 250px;

// Site header
$header-main-elements-height: 69px;
$header-height--smallscreen: 101px;
$header-height--mediumscreen: 101px;
$header-height--largescreen: 69px;

// Content Width Max (site main column)
$content-width--max: $ad-width--module + (spacing() * 3);

// Global container widths
$site-width--default: $content-width--max;
$site-width--wide: $content-width--max + $ad-width--widescreen + (spacing() * 2); // Content width + ad + gap
$site-width--max: $ad-width--panorama; // Aligns with desktop panorama width

// breakpoints
$screen-size--small: ($site-width--default + (spacing() * 2));
$screen-size--medium: ($site-width--wide + (spacing() * 2));
$screen-size--large: ($site-width--max + (spacing() * 2));
